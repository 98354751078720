<template>
  <div>

<!--    <BreadCrumbs>خانه > خدمات / محصولات > {{ad.title}} </BreadCrumbs>-->

<!--    <v-container class="d-flex flex-wrap pa-0 mt-5 justify-center">

      <div class="col-md-6 mt-5 mt-md-0 ma-0 order-1 order-md-0" >-->
<!--        <h3 class=" primary&#45;&#45;text">{{ad.title}}</h3>
        <div class="d-flex justify-space-between mt-2 mb-3">
          <small class="primary&#45;&#45;text">
            شرکت خدمات دهنده
            :
            <router-link :to="{name:'company',params:{id:ad.workId,title:ad.workTitle}}" custom v-slot="{navigate,href}">
            <a @click="navigate" :href="href" role="link" class="textlinkhover" style="color: black"  >{{ad.workTitle}}</a>
            </router-link>
          </small>
          <small class="primary&#45;&#45;text">
            {{ad.createTime | momentDate}}
          </small>

        </div>-->

        <AdSlider2 :image="ad.image" :images="ad.images"></AdSlider2>
<!--        <p class="primary&#45;&#45;text">
           توضیحات :
          <br>
          <span class="grey&#45;&#45;text">

            {{ad.desc}}

          </span>
        </p>
        <p class="primary&#45;&#45;text">
          شماره تماس :
          <a class="textlinkhover" style="color: black;text-decoration: none" :href="'tel:'+ad.tell" >{{ad.tell}}</a>
        </p>
        <p class="primary&#45;&#45;text">
          قیمت :
          <span class="black&#45;&#45;text" >{{ad.price | numFormat}}</span>
          <span class="black&#45;&#45;text" > تومان </span>
        </p>
        <p class="primary&#45;&#45;text">
          مهلت تا :
          <span class="black&#45;&#45;text" >{{ad.expireTime | momentDate}}</span>
        </p>-->
<!--      </div>-->
<!--      <div class="col-md-6 ma-0 order-0 order-md-1" >

      </div>-->

<!--    </v-container>-->
  </div>
</template>

<script>


import Vue from 'vue'

import axios from "axios";
import AdSlider2 from "../components/AdSlider2";
/*import BreadCrumbs from "../components/BreadCrumbs";*/
import moment from "jalali-moment";
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';

Vue.filter('numFormat', numFormat(numeral));
export default {
  name: 'App',
  props : {
    selected : null,
  },
  filters : {
    momentDate(date){
      return moment.unix(date).locale('fa').format('jYYYY/jMM/jDD');
    },
  },
  metaInfo() {
    return {
      title: this.ad.title
      ,link: [{rel: 'canonical', href: this.$store.state.url+'/ads/single/'+this.id+'/'+this.ad.title}],
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.ad.desc,
        },
        {
          property: 'og:description',
          content: this.ad.desc,
          vmid: 'og:description'
        },

        {
          property: 'og:image',
          vmid: 'og:image',
          content: this.$store.state.thumbnail + this.ad.image
        }, {
          property: 'og:title',
          vmid: 'og:title',
          content: 'ایران آی سیب' + '::' + this.ad.title
        }
      ],
    }
  },
  components : {
    AdSlider2,
    /*BreadCrumbs*/
  },
  data() {
    return {
/*      id : this.$route.params.id,*/
      ad : {title:''},
    }
  },
  created() {
    axios.get(this.$store.state.api + 'singleservice' , {
      params : {
        id : this.selected,
      }
    }).then(response=>{
      this.ad = response.data.data;
    });
  },
  watch : {
    selected(){
      axios.get(this.$store.state.api + 'singleservice' , {
        params : {
          id : this.selected,
        }
      }).then(response=>{
        this.ad = response.data.data;
      });
    }
  }
};
</script>
