<template>
  <div>

    <swiper :navigation="true" class="swiper-images" :options="swiperOption">
      <swiper-slide v-if="image">
        <div  style="width: 100%;height: 35vw" >
          <a :href="'https://server.iranicib.com/upload/'+image" target="_blank">
          <v-img
              width="100%"
              height="100%"
              contain
              :src="$store.state.upload+image"
              @click="showDialog(image)"
              style="cursor: zoom-in"
          >

            <div ref="imagecounter" style="min-width: 75px;cursor: default" class="profile__adsimagedesc d-flex justify-space-around align-center">
              <p class="mr-1 mb-0" style="font-size: 14px">1 از {{images.length+1}}</p>
              <img class="mb-1" src="../assets/image/Ads/camera.png" height="32" width="32"/>
            </div>

          </v-img>
          </a>
        </div >

      </swiper-slide>

      <swiper-slide v-for="(item,i) in images" :key="item + 's'">
        <div style="width: 100%;height: 35vw">
          <a :href="'https://server.iranicib.com/upload/'+item" target="_blank">
          <v-img
              width="100%"
              height="100%"
              contain
              :src="$store.state.upload+item"
              @click="showDialog(item)"
              style="cursor: zoom-in"
          >
            <div style="min-width: 75px;cursor: default" class="profile__adsimagedesc d-flex justify-space-around align-center">
              <p v-if="image.length>0" class="mr-1 mb-0" style="font-size: 14px">{{i + 2}} از {{images.length + 1}}</p>
              <p v-else class="mr-1 mb-0" style="font-size: 14px">{{i + 1}} از {{images.length}}</p>
              <img class="mb-1" src="../assets/image/Ads/camera.png" height="32" width="32"/>
            </div>

          </v-img>
          </a>

        </div>
      </swiper-slide>

      <div style="color: white !important;" class="swiper-button-next swiper-button-white navsize"  slot="button-next"></div>
      <div style="color: white !important;" class="swiper-button-prev swiper-button-white navsize"  slot="button-prev"></div>
    </swiper>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import SwiperCore, {EffectFade} from 'swiper';
import {Navigation} from 'swiper';
SwiperCore.use([Navigation]);
SwiperCore.use([EffectFade]);
export default {
  name: 'App',
  props : {
    image : null,
    images : null,
  },

  data() {
    return {
      swiperOption: {
        spaceBetween: 10,
        effect: 'fade',
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  components:{
    Swiper,
    SwiperSlide,
  },
  methods : {

  }
};
</script>


<style lang="scss" scoped>

@import "../assets/css/sliders";

.navsize * {
  width: 5% !important;
  --swiper-navigation-size: 42px !important;
}

.profile__adsimagedesc {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 110px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.5);
  right: 0;
  position: absolute;
  bottom: 10%;
  color: white;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}

.profile__adsreport {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 110px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  position: absolute;
  bottom: 10%;
  color: white;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
}

.swiper-container-rtl .swiper-button-prev:after {
  content: 'next' !important;
}

.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 42px !important;
  color: white !important;
  text-shadow: 0px 0px 5px #000000;
}

</style>


<style scoped>
.swiper-images{
  background-color: #fff !important;
}
</style>
